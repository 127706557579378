/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { triggerOnboardingEmail } from "api/email";

import { useStartPublicProcess } from "api/flowable/useStartPublicProcess";
import { useNavigate } from "react-router-dom";
import { useFormLanguage } from "utils/translations";
import { ONBOARDING_PROCESS_KEYS, useCampaignCode } from "./helpers";

export const usePublicOnboardingProcess = (): {
  handleSubmit: (data: any) => Promise<void>;
  campaingCodeData: { campaignCode?: string };
  isSubmitting: boolean;
} => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const processKey = ONBOARDING_PROCESS_KEYS.PRIVATE;
  const campaignCode = useCampaignCode(); // to initialize campaign code to form

  const campaingCodeData = campaignCode ? { campaignCode } : {};

  const formLanguage = useFormLanguage();

  const navigate = useNavigate();

  const { startProcess } = useStartPublicProcess(processKey);

  const handleSubmit = async (data: any) => {
    setIsSubmitting(true);
    // Retrieve dossierId and processId from localStorage
    const dossierId = localStorage.getItem("signicatDossierId");
    const processId = localStorage.getItem("signicatProcessId");

    try {
      const payload = {
        key: processKey,
        dossierId,
        processId,
        data: {
          import: data.import,
          tags: {
            pep: data.tags.pep,
          },
          account_number: data.import.p_accounts.split(",")[0],
          account_bic: data.import.p_accounts.split(",")[6],
          account_curr: data.import.p_currency,
          submit: true,
          tags_pep: data.tags.pep,
        },
      };
      await startProcess(payload);

      const userEmail = data?.import?.c_email ?? "";
      const fullName = data?.import?.c_name ?? "";
      const userPhone = data?.import?.c_phone1 ?? "";
      const userFirstName = fullName.split(" ")[0];
      const nameParts = fullName.split(" ");
      const userLastName = nameParts[nameParts.length - 1];
      const marketingConsent =
        data?.import?.c_profileAttribute_contact_muuttiedot_tilaan ?? "";
      // check if business onboarding
      const isBusinessOnboarding =
        processKey === ONBOARDING_PROCESS_KEYS.BUSINESS;
      // if business onboarding, send email to user with the field type equals "company"
      if (isBusinessOnboarding) {
        await triggerOnboardingEmail(
          userEmail,
          formLanguage,
          "company",
          marketingConsent
        );
      } else {
        await triggerOnboardingEmail(
          userEmail,
          formLanguage,
          "public",
          marketingConsent,
          userFirstName,
          userLastName,
          userPhone
        );
      }
      navigate(`/public-wait-portfolio-activation?lang=${formLanguage}`, {
        replace: true,
      });
    } catch (err) {
      console.error("Error completing task: ", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return { handleSubmit, campaingCodeData, isSubmitting };
};
