/* eslint-disable */

import {
  DatafeedConfiguration,
  DatafeedSymbolType,
  Exchange,
  HistoryCallback,
  LibrarySymbolInfo,
  PeriodParams,
  ResolutionString,
  ResolveCallback,
  SearchSymbolsCallback,
  SymbolExt,
  ErrorCallback as ChartingErrorCallback,
} from "charting_library/charting_library";
import { createPriceHistoryBars } from "./helpers";
import {
  COINGECKO_IDS,
  VALID_ASSET_IDS as COINS,
  VALID_CURRENCY_CODES as CURRENCIES,
  isAssetId,
  isFiatCurrencyCode,
} from "api/securities";

const EXCHANGES: Exchange[] = [
  { value: "Coingecko", name: "Coingecko", desc: "Coingecko market data" },
];

export const RESOLUTIONS: ResolutionString[] = [
  "30",
  "1D",
] as ResolutionString[];

const configurationData: DatafeedConfiguration = {
  supported_resolutions: RESOLUTIONS,
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: EXCHANGES,
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [{ name: "crypto", value: "crypto" }],
};

const generateSymbol = (
  exchange: Exchange["value"],
  fromSymbol: DatafeedSymbolType["name"],
  toSymbol: DatafeedSymbolType["name"]
) => {
  const short = `${fromSymbol}/${toSymbol}`;
  return {
    short,
    full: `${exchange}:${short}`,
  };
};

const SYMBOLS = COINS.map((coin) => {
  return CURRENCIES.map((fiat) => {
    return generateSymbol("Coingecko", coin, fiat);
  });
}).flat();

const getAllSymbols: () => SymbolExt[] = () => {
  const allSymbols = EXCHANGES.map((exchange) => {
    return SYMBOLS.map((symbol) => {
      return {
        symbol: symbol.short,
        full_name: symbol.full,
        description: symbol.short,
        exchange: exchange.value,
        type: "crypto",
        supported_resolutions: RESOLUTIONS,
      };
    });
  });
  return allSymbols.flat();
};

/**
 * Custom datafeed implementation for TradingView chart. The widgets they provide don't support
 * SEK as a currency, so we need to implement our own datafeed.
 */
export const useCoingeckoDatafeed = () => {
  // TODO use IDatafeedChartApi
  const allSymbols = getAllSymbols();
  let fetchCounter = 0;
  const datafeed = {
    // This method is called when the widget is initialized
    onReady: (callback: any) => {
      setTimeout(() => callback(configurationData));
    },
    searchSymbols: (
      userInput: string,
      exchange: Exchange["value"],
      symbolType: DatafeedSymbolType["value"],
      onResultReadyCallback: SearchSymbolsCallback
    ) => {
      const matchingSymbols = allSymbols.filter((symbol) => {
        return (
          symbol.description.includes(userInput) ||
          symbol.full_name.includes(userInput)
        );
      });
      onResultReadyCallback(matchingSymbols);
    },
    resolveSymbol: async (
      symbolName: LibrarySymbolInfo["name"],
      onSymbolResolvedCallback: ResolveCallback,
      onResolveErrorCallback: ChartingErrorCallback
    ) => {
      try {
        const symbolItem = allSymbols.find(
          ({ symbol, full_name }) =>
            symbol === symbolName || full_name === symbolName
        );

        // console.log("[resolveSymbol]: Method call", symbolName, symbolItem);

        if (!symbolItem) {
          // console.warn("[resolveSymbol]: Cannot resolve symbol", symbolName);
          throw new Error(`Failed to resolve symbol ${symbolName}`);
        }
        // Symbol information object
        const symbolInfo: LibrarySymbolInfo = {
          ticker: symbolItem.full_name,
          name: symbolItem.symbol,
          description: symbolItem.description,
          type: symbolItem.type,
          session: "24x7",
          timezone: "Etc/UTC", // TODO
          exchange: symbolItem.exchange,
          minmov: 1,
          pricescale: 100,
          has_intraday: false,
          visible_plots_set: "ohlcv",
          has_weekly_and_monthly: false,
          volume_precision: 2,
          data_status: "streaming",
          full_name: symbolItem.full_name,
          listed_exchange: symbolItem.exchange,
          format: "price",
        };

        // UThe library spec requires this to be called asynchronously
        setTimeout(() => {
          onSymbolResolvedCallback(symbolInfo);
        }, 0);
      } catch (error: any) {
        onResolveErrorCallback(error?.message);
      }
    },
    // This functions is called to fetch history data.
    getBars: async (
      symbolInfo: LibrarySymbolInfo,
      resolution: ResolutionString,
      periodParams: PeriodParams,
      onResult: HistoryCallback,
      onError: ChartingErrorCallback
    ) => {
      fetchCounter++;

      if (fetchCounter > 10) {
        console.log("[getBars]: Too many requests, throttling");
        return;
      }
      // console.log(
      //   "[getBars]: Method call",
      //   symbolInfo,
      //   resolution,
      //   periodParams
      // );
      // Example: "BTC/EUR" => [ "BTC", "EUR ]
      const [assetId, currency] = symbolInfo.name.split("/");
      if (!isAssetId(assetId)) {
        throw new Error(`Invalid assetId ${assetId}`);
      }

      if (!isFiatCurrencyCode(currency)) {
        throw new Error(`Invalid currency ${currency}`);
      }

      const coinId = COINGECKO_IDS[assetId];

      try {
        const bars = await createPriceHistoryBars(
          coinId,
          currency,
          periodParams,
          resolution
        );

        if (bars.length < periodParams.countBack) {
          console.warn("[getBars]: Less data than requested from API");
          onResult([], { noData: true });
        } else {
          // console.log("[getBars]: Data returned from API", bars);
          onResult(bars);
        }
      } catch (error: any) {
        console.error("[getBars]: Error fetching mock data", error);
        onError(error.message);
      }
    },

    subscribeBars: (
      symbolInfo: any,
      resolution: any,
      onRealtimeCallback: any,
      subscriberUID: any,
      onResetCacheNeededCallback: any
    ) => {
      // console.log(
      //   "[subscribeBars]: Method call with subscriberUID:",
      //   subscriberUID
      // );
    },
    unsubscribeBars: (subscriberUID: any) => {
      // console.log(
      //   "[unsubscribeBars]: Method call with subscriberUID:",
      //   subscriberUID
      // );
    },
  };
  return datafeed;
};
