import { useEffect, useState } from "react";
import { SecurityDetailsPosition } from "api/holdings/types";
import {
  SecurityCode,
  isSecurityCode,
  AssetId,
  SECURITY_CODE_TO_ASSET_ID,
} from "api/securities";
import { useCoingeckoDatafeed } from "./datafeed";
import {
  ChartingLibraryWidgetOptions,
  ResolutionString,
  widget,
} from "../../charting_library";

const CHART_CONTAINER_ID = "tv_chart_container";

const TradingViewChart = ({
  symbol: symbolProp,
}: {
  symbol: SecurityDetailsPosition["securityCode"];
}) => {
  const datafeed = useCoingeckoDatafeed();
  const chartSymbolCode: SecurityCode = isSecurityCode(symbolProp)
    ? symbolProp
    : "btc";
  const chartSymbolAssetId: AssetId =
    SECURITY_CODE_TO_ASSET_ID[chartSymbolCode];
  const chartCurrencyCode = "SEK"; // We use the readymade widget for Eur and only render this component for SEK users.
  const chartSymbol = `${chartSymbolAssetId}/${chartCurrencyCode}`;

  const [width, setWidth] = useState((window.innerWidth * 9) / 13);

  useEffect(() => {
    const handleResize = () => setWidth((window.innerWidth * 9) / 13);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const options: ChartingLibraryWidgetOptions = {
      // debug: process.env.NODE_ENV === "development",
      symbol: chartSymbol,
      fullscreen: false,
      container: CHART_CONTAINER_ID,
      datafeed: datafeed,
      library_path: "/charting_library/",
      locale: "en", // TODO
      autosize: false,
      disabled_features: [],
      enabled_features: [],
      interval: "1D" as ResolutionString,
      width: width,
      height: 700,
    };

    window.tvWidget = new widget(options);
  }, [chartSymbol, datafeed, width]);

  return <div id={CHART_CONTAINER_ID} />;
};

export default TradingViewChart;
