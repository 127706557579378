import { useEffect } from "react";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useLocation, useNavigate } from "react-router-dom";
import { OnboardingFooter } from "./OnboardingFooter";
import { OnboardingHeader } from "./OnboardingHeader";
import backgroundImage from "../../../../src/assets/images/kvarn_forest.jpg";

export const ThankYouKycUpdateInfo = () => {
  const { t, i18n } = useModifiedTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const lang = searchParams.get("lang");
    if (lang && i18n) {
      i18n.changeLanguage(lang);
    }
  }, [location, i18n]);

  const handleBackToHome = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col min-h-[100vh]">
      <div
        className="flex-grow"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <OnboardingHeader hideLangSelection />
        <div className="flex justify-center w-full">
          <div className="flex flex-wrap justify-center w-full lg:max-w-[600px]">
            <div className="p-8 px-4 pt-2 mt-16 mb-8 w-full bg-kvarn-dark-green rounded-lg h-fit">
              <h1 className="p-2 text-2xl leading-normal text-kvarn-light-green">
                {t("kycUpdateThankYou.title")}{" "}
              </h1>{" "}
              <p className="p-2 font-thin leading-normal text-white">
                {t("kycUpdateThankYou.text")}
              </p>
              <button
                onClick={handleBackToHome}
                className="p-2 mt-4 text-kvarn-dark-green bg-kvarn-light-green hover:bg-green-400 rounded transition-colors"
              >
                {t("kycUpdateThankYou.backToHome")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <OnboardingFooter />
    </div>
  );
};
