import { getPriceHistory } from "api/prices/priceHistory";
import { CurrencyCode } from "api/securities";
import { PeriodParams } from "../../../public/charting_library/charting_library";

/**
 * Resolution or time interval is a time period of one bar. Advanced Charts supports tick, intraday (seconds, minutes, hours), and DWM (daily, weekly, monthly) resolutions. The table below describes how to specify different types of resolutions:
 *
 * Resolution	Format	Example
 * [NOT SUPPORTED] Ticks	xT	1T — one tick
 * Seconds	xS	1S — one second
 * Minutes	x	1 — one minute
 * Hours	x minutes	60 — one hour
 * Days	xD	1D — one day
 * Weeks	xW	1W — one week
 * Months	xM	1M — one month
 * Years	xM months	12M — one year
 * Refer to Resolution for more information.
 */
type ResolutionString = string & {
  [Symbol.species]: "ResolutionString";
};

export interface Bar {
  /** Bar time.
   * Amount of **milliseconds** since Unix epoch start in **UTC** timezone.
   * `time` for daily, weekly, and monthly bars is expected to be a trading day (not session start day) at 00:00 UTC.
   * The library adjusts time according to `session` from {@link LibrarySymbolInfo}.
   */
  time: number;
  /** Opening price */
  open: number;
  /** High price */
  high: number;
  /** Low price */
  low: number;
  /** Closing price */
  close: number;
  /** Trading Volume */
  volume?: number;
}

/**
 * Valid values for the param are 1, 7, 14, 30, 90, 180, 365, max
 * daysToGet can be any number so we need to calculate the parameter
 * @param daysToGet
 *
 * days to interval of datapoints in response. (Tested, not documented afaik)
 * 1 => 30 mins
 * 7 | 14 | 30 => 4 hours
 * 90 | 180 | 365 | max => 4 days
 *
 * @ref https://apiguide.coingecko.com/exclusive-endpoints/for-paid-plan-subscribers#coins-id-ohlc
 */
// NOTE: Not in use atm since we only get max data for now
// const _resolutionToDaysParam = (
//   resolution: ResolutionString
// ): 1 | 7 | 14 | 30 | 90 | 180 | 365 | "max" => {
//   if (resolution === "30") return 1;
//   return "max";
// };

export const createPriceHistoryBars = async (
  coinId: string,
  currency: CurrencyCode,
  periodParams: PeriodParams,
  resolution: ResolutionString
): Promise<Bar[]> => {
  const history = await getPriceHistory(coinId, currency, "max");

  const bars = history.map(([timeMs, open, high, low, close, volume]) => ({
    time: timeMs,
    open,
    high,
    low,
    close,
    volume,
  }));

  return bars;
};
