import { keycloakService } from "../../services/keycloakService";
// [ timestamp ms, open, high, low, close, volume ]
type PriceHistoryItem = [number, number, number, number, number, number?];
export type PriceHistory = PriceHistoryItem[];

/**
 *
 *
 * @param coinId
 * @param currency
 * @param daysToGet // can only limit data by days
 * @param getDailyBars // We want daily bars if bar duration is 1 day or more
 * @returns
 *
 * @ref https://apiguide.coingecko.com/exclusive-endpoints/for-paid-plan-subscribers#coins-id-ohlc
 * @ref about data granularity: https://apiguide.coingecko.com/getting-started/10-min-tutorial-guide/2-get-historical-data
 */
export const getPriceHistory = async (
  coinId: string, // coingecko id ie. "bitcoin"
  currency: string, // ie. "eur"
  daysToGet: number | "max"
): Promise<PriceHistory> => {
  try {
    const token = await keycloakService.getToken();
    const url =
      `${process.env.REACT_APP_COINGECKO_DATA_API_URL}/coins/${coinId}/ohlc` +
      `?vs_currency=${currency}` +
      `&days=${daysToGet}`;

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
