import { MutableRefObject, useState, useEffect } from "react";
import { useGetAllPortfolioHoldingDetails } from "api/holdings/useGetAllPortfolioHoldingDetails";
import { useGetPortfolioFeeDetails } from "api/holdings/useGetPortfolioFeeDetails";
import { useGetPortfolioHoldings } from "api/holdings/useGetPortfolioHoldings";
import { useGetSecurityDetails } from "api/holdings/useGetSecurityDetails";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { convertSecurityCodeSekToSecurityCode } from "api/securities";
import {
  SecurityCodeSek,
  SecurityCode,
  SecurityCodeWithoutTransferSupport,
} from "api/securities";
import { useCryptoTrade } from "api/trading/useCryptoTrade";
import { useGetBuyData } from "api/trading/useGetBuyData";
import { useGetBuyDataWithOpenOrders } from "api/trading/useGetBuyDataWithOpenOrderStatus";
import InfoIcon from "assets/info-icon.svg";
import {
  PortfolioSelect,
  DownloadableDocument,
  Button,
  Input,
  LabeledDiv,
} from "components/index";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useGetContactIdData } from "providers/ContactIdProvider";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { CONTAINERS, LABELS } from "testIds";
import { Chart } from "./Chart";
import { ErrorMessageDisplay } from "./ErrorMessageDisplay";
import { OpenOrderMessageDisplay } from "./OpenOrderMessageDisplay";
import { SuccessMessageDisplay } from "./SuccessMessageDisplay";
import ToggleSwitch from "./toggle";
import { validateAmountTrade, validateUnitsTrade } from "./utils";
import { getDecimalPlaces } from "./utils";
import { isLimitOrderOnly } from "./utils";
import useCryptoMinMax from "../../../api/minmax/useFetchMinMax";
import useFetchQuotes from "../../../api/prices/useFetchPrices";
import { useTradablePortfolioSelect } from "../useTradablePortfolioSelect";

export interface BuyModalInitialData {
  id: number;
}

interface BuyModalProps extends BuyModalInitialData {
  modalInitialFocusRef: MutableRefObject<null>;
  onClose: () => void;
  onTradeCompletion?: () => void;
}

export const BuyModalContent = ({
  modalInitialFocusRef,
  id: securityId,
}: BuyModalProps) => {
  // Get prices from B2C2 api using useFetchPrices hook

  const {
    data: security = {
      name: "",
      url2: undefined,
      type: { code: undefined },
      latestMarketData: undefined,
      fxRate: 1,
      securityCode: "",
      currency: { securityCode: "" },
      tagsAsSet: [],
    },
  } = useGetSecurityDetails(securityId.toString());

  const { name: securityName, url2 = undefined } = security;

  const [tradeType, setTradeType] = useState<"units" | "amount">("units");

  const [selectedOrderType, setSelectedOrderType] = useState<
    "marketOrder" | "limitOrder"
  >("marketOrder");

  useEffect(() => {
    if (
      security.securityCode &&
      isLimitOrderOnly(
        security.securityCode as
          | SecurityCode
          | SecurityCodeWithoutTransferSupport
      )
    ) {
      setSelectedOrderType("limitOrder");
      setTradeType("units");
      setAmount("");
      setlimitOrderPrice("");
    }
  }, [security.securityCode]);

  const [allowedSlippage, setAllowedSlippage] = useState<number>(1.003);

  const { t } = useModifiedTranslation();
  const { selectedContactId } = useGetContactIdData();
  const { data: { portfolios } = { portfolios: [] } } = useGetContactInfo(
    false,
    selectedContactId
  );
  const { portfolioId, setPortfolioId, portfolioOptions } =
    useTradablePortfolioSelect();
  const {
    loading,
    data: { availableCash = 0, currency: portfolioCurrency = "EUR" } = {},
    refetch,
  } = useGetBuyData(portfolioId.toString());

  const quotesData = useFetchQuotes(portfolioCurrency as "EUR" | "SEK");

  const [amount, setAmount] = useState("");

  const [limitOrderPrice, setlimitOrderPrice] = useState("");

  const foundPortfolio = portfolios.find(
    (portfolio) => portfolio.id === portfolioId
  );

  const foundPortfolioShortName =
    (foundPortfolio ? foundPortfolio.shortName : portfolios[0].shortName) || "";

  const portfolioShortName = foundPortfolioShortName.toString() || "";

  // get the available cash adjusted for open orders
  const {
    data: { availableCash: availableCashAdjusted = 0 } = {},
    totalBuys,
    refetch: refetchAvailableCashAdjusted,
  } = useGetBuyDataWithOpenOrders(portfolioId.toString());

  // Set state for success and error messages
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [openTradeMessageVisible, setOpenTradeMessageVisible] = useState(false);

  const unitPrice = Number(quotesData[security.name]?.buy);
  const unitPriceWithSlippage = (unitPrice * allowedSlippage).toFixed(
    getDecimalPlaces(security.securityCode)
  );

  // Convert selectedContactId to sting
  const sContactId = selectedContactId.toString();

  const orderType = selectedOrderType === "limitOrder" ? "Limit" : "Market";

  const [endTime, setEndTime] = useState<string>("");

  const {
    handleTrade: handleBuy,
    submitting,
    response,
  } = useCryptoTrade({
    portfolioShortName,
    units: Number(amount),
    unitPrice:
      selectedOrderType === "limitOrder"
        ? limitOrderPrice
        : unitPriceWithSlippage,
    transactionTypeCode: "B",
    contact: sContactId,
    securityCode: security.securityCode,
    currency: security.currency.securityCode,
    dryRun: false,
    orderType: orderType,
    ...(tradeType === "amount" && { unitAsset: "quote" }),
    ...(selectedOrderType === "limitOrder" &&
      endTime && { endTime: new Date(endTime).getTime() }),
  });

  // Get feePercentage
  const { data: { feePercentage = 0 } = {} } =
    useGetPortfolioFeeDetails(portfolioId.toString()) || {};

  const getUnitPrice = () => {
    if (selectedOrderType === "limitOrder") {
      return limitOrderPrice;
    }
    return quotesData[security.name]?.buy || 0;
  };

  // Calculate fee if less than 1 EUR or 10 SEK then set to 1 EUR or 10 SEK
  const minFee = portfolioCurrency === "SEK" ? 10 : 1;

  const unitsFee = Math.max(
    Number(
      selectedOrderType === "limitOrder"
        ? limitOrderPrice
        : quotesData[security.name]?.buy || 0
    ) *
      Number(amount) *
      Number(feePercentage),
    minFee
  ).toFixed(2);

  const amountFee = Math.max(
    Number(amount) * Number(feePercentage),
    minFee
  ).toFixed(2);

  const tentativeFee = tradeType === "units" ? unitsFee : amountFee;

  // Handle success message
  const handleSuccessfulTrade = () => {
    setSuccessMessageVisible(true);
  };

  // Handle open trade
  const handleOpenTrade = () => {
    setOpenTradeMessageVisible(true);
  };

  // Get min and max prices from B2C2 api using useFetchMinMax hook
  const minMaxData = useCryptoMinMax();

  // refetch holdings after a successful trade
  const { refetch: refetchHolding } = useGetPortfolioHoldings(
    portfolioId.toString()
  );

  const { refetch: refetchHoldingPositions } = useGetAllPortfolioHoldingDetails(
    portfolioId.toString()
  );
  const [inputError, setInputError] = useState("");
  const [formError, setFormError] = useState("");

  const handleBuyClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setFormError("");

    try {
      const response = await handleBuy();

      if (response.status === "executed") {
        handleSuccessfulTrade();
        await Promise.all([
          refetch(),
          refetchHolding(),
          refetchHoldingPositions(),
          refetchAvailableCashAdjusted(),
        ]);
      } else if (response.status === "accepted") {
        handleOpenTrade();
      } else {
        const errorMessageKey =
          response.message === "Insufficient funds" ||
          response.message === "Insufficient units"
            ? "tradingModal.insufficientFunds"
            : "tradingModal.priceMovement";
        setFormError(t(errorMessageKey));
      }
    } catch (error) {
      console.error("Error during buy operation:", error);
      setFormError(t("tradingModal.generalError"));
    }
  };

  const handleLimitOrderPriceChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const inputValue = event.currentTarget.value.replace(/,/g, ".");
    const regex = /^(0(\.\d*)?|[1-9]\d*(\.\d*)?)$/;
    if (regex.test(inputValue) || inputValue === "") {
      setlimitOrderPrice(inputValue);
    }
  };

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const inputValue = event.currentTarget.value.replace(/,/g, ".");
    const regex = /^(0(\.\d*)?|[1-9]\d*(\.\d*)?)$/;
    if (regex.test(inputValue) || inputValue === "") {
      setAmount(inputValue);
    }

    const value = parseFloat(inputValue);

    const unitPriceToUse = getUnitPrice();

    const cashNeeded =
      tradeType === "units"
        ? Number(unitPriceToUse) * Number(value)
        : Number(value);

    const enoughCash =
      !isNaN(availableCashAdjusted) && availableCashAdjusted >= cashNeeded;

    if (!enoughCash) {
      setInputError(t("tradingModal.insufficientFunds"));
      return;
    }

    if (tradeType === "units") {
      validateUnitsTrade(
        inputValue,
        setInputError,
        t,
        minMaxData[security.name]
      );
    }
    if (tradeType === "amount") {
      validateAmountTrade(
        inputValue,
        setInputError,
        t,
        Number(unitPriceWithSlippage),
        minMaxData[security.name]
      );
    }
  };

  const getTotalToShow = () => {
    // If selected option is limitOrder we just return the limitOrderPrice times the amount

    if (selectedOrderType === "limitOrder" && tradeType === "units") {
      return Number((Number(limitOrderPrice) * Number(amount)).toFixed(3));
    }

    if (selectedOrderType === "limitOrder" && tradeType === "amount") {
      const minData = minMaxData[security.name]?.minSizeIncrement;
      const decimalPlaces = getDecimalPlaces(minData);
      return (Number(amount) / Number(limitOrderPrice)).toFixed(decimalPlaces);
    }

    const unitPrice = Number(quotesData[security.name]?.buy) || 0;

    // If tradeType is units we return the total price of the trade to display total price
    if (tradeType === "units") {
      return (unitPrice * Number(amount)).toFixed(3);
    }

    if (tradeType === "amount") {
      const minData = minMaxData[security.name]?.minSizeIncrement;
      const decimalPlaces = getDecimalPlaces(minData);
      // If tradeType is amount we return the amount to display the total quantity of the trade
      return (Number(amount) / unitPrice).toFixed(decimalPlaces);
    }
  };

  const minToShow =
    tradeType === "units"
      ? minMaxData[security.name]?.minimumSize.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 8,
        })
      : (minMaxData[security.name]?.minimumSize * unitPrice).toFixed(2);

  const maxToShow =
    tradeType === "units"
      ? minMaxData[security.name]?.maximumSize
      : (minMaxData[security.name]?.maximumSize * unitPrice).toFixed(2);

  const securityCodeWithoutSek =
    portfolioCurrency === "SEK"
      ? convertSecurityCodeSekToSecurityCode(
          security.securityCode as SecurityCodeSek
        )
      : security.securityCode;

  const [isEnabled, setIsEnabled] = useState(false);

  const handleToggle = (newValue: boolean) => {
    setIsEnabled(newValue);
  };

  const [allowedSlippageInput, setAllowedSlippageInput] = useState<string>(
    ((allowedSlippage - 1) * 100).toFixed(2)
  );

  const handleSlippageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setAllowedSlippageInput(inputValue);
    const numericValue = parseFloat(inputValue);
    if (!isNaN(numericValue)) {
      setAllowedSlippage(numericValue / 100 + 1);
    }
  };

  useEffect(() => {
    if (selectedOrderType === "limitOrder") {
      setTradeType("units");
    }
  }, [selectedOrderType]);

  return (
    <div className="grid lg:grid-cols-4 gap-2 min-h-[650px]">
      <div className="hidden lg:block lg:col-span-3">
        <Chart
          securityCode={securityCodeWithoutSek}
          currency={portfolioCurrency}
        />
      </div>
      <div className="lg:col-span-1 lg:ml-8" data-testid={CONTAINERS.BUY_MODAL}>
        {!successMessageVisible && !openTradeMessageVisible ? (
          <>
            <LabeledDiv
              label={t("tradingModal.securityName")}
              className="text-2xl font-semibold"
            >
              {securityName}
            </LabeledDiv>
            {url2 && (
              <div className="w-fit">
                <DownloadableDocument
                  url={url2}
                  label={t("tradingModal.kiid")}
                />
              </div>
            )}
            <PortfolioSelect
              portfolioOptions={portfolioOptions}
              portfolioId={portfolioId}
              onChange={(newPortfolio) => setPortfolioId(newPortfolio.id)}
              label={t("tradingModal.portfolio")}
            />
            {/* Dropdown menu added above quantity input field */}
            <div className="my-4">
              <label
                htmlFor="tradeOption"
                className="block text-sm font-medium text-gray-700"
              >
                {t("tradingModal.strategy")}
              </label>
              {isLimitOrderOnly(
                security.securityCode as
                  | SecurityCode
                  | SecurityCodeWithoutTransferSupport
              ) ? (
                <div className="py-2 px-3 mt-1 text-base sm:text-sm text-gray-700 bg-gray-100 rounded-md">
                  {t("tradingModal.limitOrder")}
                </div>
              ) : (
                <select
                  id="tradeOption"
                  name="tradeOption"
                  className="block py-2 pr-10 pl-3 mt-1 w-full text-base sm:text-sm rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none"
                  value={selectedOrderType}
                  onChange={(e) => {
                    setSelectedOrderType(
                      e.target.value as "marketOrder" | "limitOrder"
                    );
                    setAmount("");
                    setlimitOrderPrice("");
                  }}
                >
                  <option value="marketOrder">
                    {t("tradingModal.marketOrder")}
                  </option>
                  <option value="limitOrder">
                    {t("tradingModal.limitOrder")}
                  </option>
                </select>
              )}
            </div>

            <LabeledDiv
              label={t("tradingModal.availableCash")}
              className="text-xl font-semibold text-gray-700"
            >
              {portfolioCurrency &&
                t("numberWithCurrency", {
                  value: availableCashAdjusted,
                  currency: portfolioCurrency,
                })}
            </LabeledDiv>
            <div className="mt-2 w-full">
              <Input
                ref={modalInitialFocusRef}
                value={amount || ""}
                onChange={handleInputChange}
                label={
                  tradeType === "units"
                    ? t("tradingModal.unitsInputLabel")
                    : t("tradingModal.tradeAmountInputLabel", {
                        currency: portfolioCurrency,
                      })
                }
                type="text"
                pattern="^(0(\.\d*)?|[1-9]\d*(\.\d*)?)$"
                error={inputError}
              />
            </div>

            {selectedOrderType === "limitOrder" && (
              <>
                <Input
                  value={limitOrderPrice}
                  onChange={handleLimitOrderPriceChange}
                  label={t("tradingModal.limitPrice")}
                  type="text"
                />
              </>
            )}
            {selectedOrderType === "limitOrder" && (
              <div className="flex justify-between items-center mt-4 text-sm font-normal">
                <p>{t("tradingModal.toggleLabel")}</p>
                <ToggleSwitch enabled={isEnabled} setEnabled={handleToggle} />
              </div>
            )}
            {isEnabled && selectedOrderType === "limitOrder" && (
              <>
                <div className="mt-4">
                  <label
                    htmlFor="endTime"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("tradingModal.endTime")}
                  </label>
                  <input
                    type="datetime-local"
                    id="endTime"
                    name="endTime"
                    className="block py-2 pr-10 pl-3 mt-1 w-full text-base sm:text-sm rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none"
                    value={endTime.slice(0, 16)} // Ensure the format matches datetime-local input
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                </div>
                <hr className="mt-4 border-t border-gray-200" />
                <div className="flex mt-4 text-sm font-normal">
                  <div>
                    {t("tradingModal.maxPriceWithSlippage")}:{" "}
                    {unitPriceWithSlippage}{" "}
                    {portfolioCurrency === "SEK" ? "kr" : "EUR"}
                  </div>
                </div>
                <div className="flex mt-4 text-sm font-normal">
                  <div>
                    {t("tradingModal.openOrdersTotal")}: {totalBuys.toFixed(2)}{" "}
                    {portfolioCurrency === "SEK" ? "kr" : "EUR"}
                  </div>
                </div>
                <div className="flex mt-4 text-sm font-normal">
                  <div>
                    {t("tradingModal.availableCashAdjustedForOpenOrders")}:{" "}
                    {availableCashAdjusted.toFixed(2)}{" "}
                    {portfolioCurrency === "SEK" ? "kr" : "EUR"}
                  </div>
                </div>
                <hr className="mt-4 border-t border-gray-200" />
              </>
            )}
            {selectedOrderType === "marketOrder" && (
              <div className="flex justify-between items-center mt-4 text-sm font-normal">
                <p>{t("tradingModal.toggleLabel")}</p>
                <ToggleSwitch enabled={isEnabled} setEnabled={handleToggle} />
              </div>
            )}
            {isEnabled && selectedOrderType === "marketOrder" && (
              <>
                <div
                  data-tooltip-id="my-tooltip-1"
                  className="flex gap-8 items-center text-sm font-normal"
                >
                  <label
                    htmlFor="allowedSlippage"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("tradingModal.allowedSlippage")}{" "}
                    <img
                      src={InfoIcon}
                      data-tooltip-id="my-tooltip-2"
                      data-tooltip-html={t("tradingModal.allowedSlippageInfo")}
                      alt="Info"
                      className="inline ml-1 w-4 h-4"
                    />
                  </label>
                  <ReactTooltip
                    id="my-tooltip-2"
                    place="bottom"
                    variant="dark"
                  />
                  <div className="flex mt-2 ml-auto">
                    <Input
                      id="allowedSlippage"
                      label=""
                      name="allowedSlippage"
                      type="number"
                      step="0.01"
                      min="0"
                      value={allowedSlippageInput}
                      onChange={handleSlippageChange}
                      className="block py-2 my-2 w-20 text-base sm:text-sm text-center rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none"
                    />
                  </div>
                </div>
                <hr className="mt-4 border-t border-gray-200" />
                <div className="flex mt-4 text-sm font-normal">
                  <div>
                    {t("tradingModal.maxPriceWithSlippage")}:{" "}
                    {unitPriceWithSlippage}{" "}
                    {portfolioCurrency === "SEK" ? "kr" : "EUR"}
                  </div>
                </div>
                <div className="flex mt-4 text-sm font-normal">
                  <div>
                    {t("tradingModal.openOrdersTotal")}: {totalBuys.toFixed(2)}{" "}
                    {portfolioCurrency === "SEK" ? "kr" : "EUR"}
                  </div>
                </div>
                <div className="flex mt-4 text-sm font-normal">
                  <div>
                    {t("tradingModal.availableCashAdjustedForOpenOrders")}:{" "}
                    {availableCashAdjusted.toFixed(2)}{" "}
                    {portfolioCurrency === "SEK" ? "kr" : "EUR"}
                  </div>
                </div>
                <hr className="mt-4 border-t border-gray-200" />
              </>
            )}
            <div className="inline-flex gap-8 mt-4 text-sm font-normal">
              <div data-testid={LABELS.MIN_TRADE_AMOUNT}>Min: {minToShow}</div>{" "}
              <div> Max: {maxToShow}</div>
            </div>
            <div className="flex flex-col text-sm font-normal">
              {t("tradingModal.indicativeFee")}: {tentativeFee}{" "}
              {portfolioCurrency}
            </div>
            <div className="flex flex-col mb-4 text-sm font-normal">
              {t("tradingModal.feePercentage")}:{" "}
              {(feePercentage * 100).toFixed(1)}
            </div>
            {selectedOrderType === "marketOrder" && (
              <div className="flex overflow-hidden font-medium leading-5 bg-gray-50 rounded-md divide-x ring-1 shadow-sm pointer-events-auto select-none divide-slate-400/20 text-[0.8125rem] ring-slate-700/10">
                <button
                  className={`text-center cursor-pointer py-2 px-4 flex-1 ${
                    tradeType === "units" ? "bg-gray-200" : ""
                  }`}
                  onClick={() => {
                    setTradeType("units");
                    setAmount("");
                    setlimitOrderPrice("");
                  }}
                >
                  {t("tradingModal.unitsButtonLabel")}
                </button>

                <button
                  className={`text-center cursor-pointer py-2 px-4 flex-1 ${
                    tradeType === "amount" ? "bg-gray-200" : ""
                  }`}
                  onClick={() => {
                    setTradeType("amount");
                    setAmount("");
                    setlimitOrderPrice("");
                  }}
                >
                  {t("tradingModal.tradeAmountButtonLabel")}
                </button>
              </div>
            )}
            <hr className="my-2" />
            <div className="flex flex-col gap-4 items-stretch ">
              <div className="text-3xl font-semibold text-center">
                <div className="text-base font-normal">
                  {t("tradingModal.unitPrice")}
                </div>
                {Number(quotesData[security.name]?.buy || 0).toFixed(
                  getDecimalPlaces(security.securityCode)
                )}{" "}
                {portfolioCurrency}
                {/* Calculate the total price and convert it back to string. Show price with two decimals*/}
                <div className="mt-4 text-base font-normal">
                  {tradeType === "units"
                    ? t("tradingModal.tradeAmount")
                    : t("tradingModal.unitsInputLabel")}
                </div>
                {getTotalToShow()}{" "}
                {tradeType === "units"
                  ? portfolioCurrency
                  : security.securityCode}
              </div>
              <Button
                disabled={
                  amount === "0" ||
                  loading ||
                  !!inputError ||
                  amount === "" ||
                  (selectedOrderType === "limitOrder" && limitOrderPrice === "")
                }
                isLoading={submitting}
                onClick={handleBuyClick}
                type="button" // Explicitly set button type
              >
                {t("tradingModal.buyButtonLabel")}
              </Button>
            </div>
          </>
        ) : openTradeMessageVisible ? (
          <OpenOrderMessageDisplay
            t={t}
            response={response}
            limitOrderPrice={limitOrderPrice}
            tentativeFee={tentativeFee}
            currency={portfolioCurrency}
          />
        ) : successMessageVisible && response ? (
          <SuccessMessageDisplay
            response={{
              ...response,
              unitPrice: Number(response.unitPrice),
              securityCode: security.securityCode as
                | SecurityCode
                | SecurityCodeWithoutTransferSupport,
            }}
            t={t}
            currency={portfolioCurrency}
          />
        ) : null}
        {formError && <ErrorMessageDisplay errorMessage={formError} t={t} />}
        <hr className="my-1" />
        <div className="text-xs text-center text-gray-600 max-w-[375px]">
          {t("tradingModal.buyDisclaimer")}
        </div>
      </div>
    </div>
  );
};
